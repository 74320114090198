<template>
  <div class="d-flex align-items-center justify-content-center py-4 px-2 bg-gray-100 vh-100">
    <div class="card-wrapper">
      <div class="card">
        <div class="card-header justify-content-center px-2">
          <div class="card-heading text-primary d-flex">
            <!-- logo -->
            <b-img
              :src="heroleadsLogo"
              alt="Heroleads Logo"
            />
            <h2 class="brand-text text-primary ml-1 mt-2">
              HerovisionX
            </h2>
          </div>
        </div>
        <div class="card-body">
          <h3 class="mb-1">
            Forgot Password? 🔒
          </h3>
          <p class="text-muted text-sm mb-1">
            Enter your email and we'll send you instructions to reset your password
          </p>
          <!-- form -->
          <validation-observer ref="observer">
            <b-form @submit.prevent="validationForm">
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                variant="primary"
                block
                type="submit"
                class="btn-send-link"
              >
                Send Reset Link
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <b-link :to="{name:'heroai-client-login'}" class="back-to-login-link">
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
          </b-card-text>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BLink, BCardText, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import AxiosConfig from '@/services/AxiosConfigs'
import SweetAlert from '@/services/SweetAlert'
import axios from '@/libs/axios'
import ErrorService from '@/services/ErrorService'

export default {
  components: {
    BLink,
    BCardText,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      // validation
      required,
      email,
    }
  },
  computed: {
    heroleadsLogo() {
      return require('@/assets/images/logo/heroleads-logo.png')
    },

    loginPageImage() {
      return require('@/assets/images/pages/login_page_image.svg')
    },
  },
  methods: {
    async validationForm() {
      const axiosConfig = AxiosConfig.getJsonWithoutToken()
      const validationResult = await this.$refs.observer.validate()

      if (validationResult) {
        let alertResult = await this.$swal({ ...SweetAlert.confirm, text: 'Do you want to reset password?' })

        if (alertResult.value) {
          this.showOverlay = true

          const body = {
            email: this.userEmail,
          }

          try {
            await axios.post('client/send-reset-password-email', body, axiosConfig)

            alertResult = await this.$swal({ ...SweetAlert.success, text: "We've sent a password reset link to your email address" })

            if (alertResult.value) {
              await this.$router.push({ name: 'heroai-client-login' })
            }
          } catch (error) {
            await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
          }

          this.showOverlay = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style lang="scss" scoped>
.card-wrapper {
  max-width: 400px;
}

.btn-send-link, .btn-send-link:active, .btn-send-link:focus {
  background-color: #ff3e00 !important;
  border-color: #ff3e00 !important;
  border-radius: 50px;
}

.btn-send-link:hover {
  box-shadow: 0 8px 25px -8px #ff3e00 !important;
}

.back-to-login-link {
  color: #ff3e00 !important;
}
</style>
